import { format } from "date-fns";
import { Link } from "gatsby";
import React from "react";
import { buildImageObj, cn, getEventUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";
import Figure from "./Figure"

import styles from "./event-preview.module.css";
import { responsiveTitle3 } from "./typography.module.css";

const renderDay = (day) => {
  const week = {
    0: 'NEDĚLE',
    1: 'PONDĚLÍ',
    2: 'ÚTERÝ',
    3: 'STŘEDA',
    4: 'ČTVRTEK',
    5: 'PÁTEK',
    6: 'SOBOTA',
  }

  return week[day]
}

function BlogPostPreview(props) {
  const {
    homePage,
    eventDate,
    slug: {
      current: slug
    } = {},
    title,
    subtitle,
    mainImage,
    _rawExcerpt,
    top,
  } = props

  const date = new Date(eventDate)
  if (homePage) {
    return (
      <Link
        className={styles.inGrid}
        to={getEventUrl(eventDate, slug)}
      >
      <div className={cn(styles.row, top === true && styles.yellow, "flex items-center h-20 md:h-16 text-lg text-white tracking-wide ")}>
        <div className="w-1/3 h-20 md:h-16">
          <h4 className={cn(styles.yellow, 'text-base')}>
            {`${renderDay(date.getDay())} / ${eventDate.substring(11, 16)}`}
          </h4>
          <h4 className="font-semibold">
            {`${eventDate.substring(8, 10)}/${eventDate.substring(5, 7)}/${eventDate.substring(0, 4)}`}
          </h4>
        </div>
        <div className="h-20 md:h-16 flex-grow">
            <h4 className="text-base text-gray-400">{subtitle}</h4>
            <h3 className={cn(!subtitle && "mt-6", "font-semibold")}>{title}</h3>
        </div>
        {
          top === true && <h4 className={cn(styles.yellow, "mt-2 text-base")}>{'Top akce'}</h4>
        }
      </div>
      </Link>
    );
  }

  return (
    <Link
      className={styles.inGrid}
      to={getEventUrl(eventDate, slug)}
    >
      <div>
        <h4 className={cn(styles.yellow, "text-xl font-semibold tracking-wide")}>
          {`${eventDate.substring(8, 10)}/${eventDate.substring(5, 7)}/${eventDate.substring(0, 4)}`}
        </h4>
      </div>
      <div className={styles.leadMediaThumb}>
        {mainImage && mainImage.asset && (
          <Figure
            src={imageUrlFor(buildImageObj(mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .auto("format")
              .url()}
            alt={mainImage.alt}
            caption={mainImage.caption}
          />
        )}
      </div>
      <div className={styles.text}>
        <h3 className={cn(responsiveTitle3, styles.title)}>{title}</h3>
        {_rawExcerpt && (
          <div className={styles.excerpt}>
            <PortableText blocks={_rawExcerpt} />
          </div>
        )}
      </div>
    </Link>
  );
}

export default BlogPostPreview;

import React from "react";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import { cn } from "../lib/helpers";
import styles from './blog-post-preview-grid.module.css'
import partnersStyles from './partners.module.css'

const maybeImage = image => {
  let img = null;
  if (
    image &&
    image.asset
  ) {
    const fluidProps = getFluidGatsbyImage(
      image.asset._id,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <img key={image.asset._id} className="w-full mx-auto" src={fluidProps.src} alt={image.alt} />
    );
  }

  return img;
};

const TextEditor = props => {
  const images = props.partnersArray.map(partner => maybeImage(partner))

  return (
    <section className={cn(styles.greyBackground), "py-12"}>
      <div className="container max-w-5xl mx-auto m-8">
      <h1 className="text-white text-center text-4xl uppercase font-semibold tracking-wide">{props.title}</h1>
      <div className={partnersStyles.container} >
        {images}
      </div>
      </div>
    </section>
  );
};

export default TextEditor;

import React from "react";

import { cn } from "../lib/helpers";

import Button from './button'
import BlogPostPreview from "./blog-post-preview";
import styles from './blog-post-preview-grid.module.css'
function BlogPostPreviewGrid({ homePage, nodes = {}, title }) {
  const {
    edges = []
  } = nodes

  if (homePage === true) {
    return (
      <section className={cn(styles.grayBackground, "relative py-20 overflow-hidden")}>
      <svg
          className='absolute top-0 right-0'
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1149.91 163.56"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="Layer_2-2" data-name="Layer 2">
              <g id="Layer_1-2-2" data-name="Layer 1-2">
                <path
                  id="Path_8"
                  data-name="Path 8"
                  d="M1149.91,162.06H1002.85V105.18H957V53.77H921.17V1.5H0"
                  style={{
                    fill: 'none',
                    stroke:'#e4bd00',
                    strokeWidth:'2px',
                  }}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <h1 className="text-center text-white text-4xl uppercase font-semibold tracking-wide">{title}</h1>
        <div className="container max-w-5xl mx-auto m-8 lg:mt-32 md:mt-16">
          <div className="flex flex-wrap -mx-2 overflow-hidden md:-mx-2 lg:-mx-2 xl:-mx-2">
                {edges.slice(0,3).map((edge = {}) => {
                  const {
                    node = {}
                  } = edge

                  return (
                  <div className="text-white my-2 px-2 w-full overflow-hidden md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3" key={node.id}>
                    <BlogPostPreview {...node} />
                  </div>
                )})}
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            route={'/novinky'}
            title={'ZOBRAZIT VŠECHNY NOVINKY'}
          />
        </div>
      </section>
      );
  }

  return (
  <section className="border-b py-20">
    <h1 className="text-white text-center text-4xl uppercase font-semibold tracking-wide">{title}</h1>
    <div className="container max-w-5xl mx-auto m-8">
    <h2 className="text-xl uppercase tracking-wide">{'Informace a fotoreporty z akcí:'}</h2>
      <div className="flex flex-wrap -mx-2 overflow-hidden md:-mx-2 lg:-mx-2 xl:-mx-2 mt-4">
            {edges.map((edge = {}) => {
              const {
                node = {}
              } = edge

              return (
              <div className="my-2 px-2 w-full overflow-hidden md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3" key={node.id}>
                <BlogPostPreview {...node} />
              </div>
            )})}
      </div>
    </div>
  </section>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default BlogPostPreviewGrid;

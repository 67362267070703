import React, { useState, useEffect } from "react";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { cn, getEventUrl } from "../lib/helpers";
import clientConfig from "../../client-config";
import Button from "./button";

import styles from "./hero.module.css";
import "./hero.css"

const getImage = illustration => {
  if (illustration && illustration && illustration.asset && !illustration.disabled) {
    const fluidProps = getFluidGatsbyImage(
      illustration.asset._id,
      { maxWidth: 1240 },
      clientConfig.sanity
    )

    return {
      src: fluidProps.src,
      alt: illustration.alt,
    }
  }
  return {
    src: undefined,
    alt: undefined,
  }
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const reduceEvents = events => {
  const now = new Date()
  const reducer = (acc, current) => {
    const {
      node: {
        top,
        eventDate,
        ...props
      } = {}
    } = current
    const date = new Date(eventDate)

    if (now < date) {
      if (top === true) {
        return {
          future: acc.future,
          top: [
            ...acc.top,
            {
              top,
              eventDate,
              ...props
            },
          ]
        }
      } else {
        return {
          future: [
            ...acc.future,
            {
              top,
              eventDate,
              ...props
            },
          ],
          top: acc.top,
        }
      }
    }

    return acc
  }
  const reducedEvents = events.reduce(reducer, {top: [], future: []})
  return [
    ...reducedEvents.top,
    ...reducedEvents.future.slice(0,3),
  ]
}

const Hero = (props = {}) => {
  const {
    events: {
      edges = []
    } = {},
  } = props
  const size = useWindowSize();
  const reducedEvents = reduceEvents(edges)

  return (
  <div className={cn(styles.grayBackground, "w-full -mt-20")}>
    <CarouselProvider
      naturalSlideWidth={size.width}
      naturalSlideHeight={size.height*0.8}
      totalSlides={reducedEvents.length}
      interval={6000}
      isPlaying={true}
      infinite={true}
    >
      <Slider>
        {
          reducedEvents.map((event, index) => {
            const {
              mainImage,
              title,
              subtitle,
              id,
              eventDate,
              slug: {
                current: slug,
              } = {}
            } = event
            const image = getImage(mainImage)

            return (
              <Slide
                index={index}
                key={id}
              >
              <div
                className="relative overflow-hidden w-full h-full"
              >
                  <div
                    className="static hero-image border-b-2"
                    style={{
                      background: `url(${image.src}) no-repeat center top`,
                      overflow: 'hidden',
                      backgroundSize: 'cover',
                      width: '115%',
                      height: '100%',
                      borderColor: '#E4BD00',
                    }}
                  />
                  <div
                    className={cn(styles.grayBackground, 'border-t-2 text-white absolute left-0 bottom-0 py-4 px-8 max-w-md tracking-wider font-semibold')}
                  >
                    <h1
                      className="text-3xl"
                    >
                      {`${eventDate.substring(8, 10)}/${eventDate.substring(5, 7)}/${eventDate.substring(0, 4)}`}
                    </h1>
                    <h2
                      className="text-2xl"
                    >
                      {title}
                    </h2>
                    <h2
                      className="text-base"
                    >
                      {subtitle}
                    </h2>
                    <Button
                      route={getEventUrl(eventDate, slug)}
                      title={'ČÍST VÍCE'}
                    />
                    <div
                      className={cn(styles.schody, 'absolute top-0 left-0')}
                    >
                    <div
                      className={cn(styles.schod1, 'border-t-2 border-r-2')}
                    />
                    <div
                      className={cn(styles.schod2, 'border-t-2 border-r-2')}
                    />
                    <div
                      className={cn(styles.schod3, 'border-t-2 border-r-2')}
                    />
                    </div>
                  </div>
                </div>
              </Slide>
            )
          })
        }
      </Slider>
    </CarouselProvider>
  </div>
  );
}

export default Hero;

import React from "react";
import { Link, navigate } from "gatsby";

import { cn } from "../lib/helpers";

import styles from "./button.module.css";

const doNavigate = target => {
  if (!target || !target.length) {
    return;
  }
  const internal = /^\/(?!\/)/.test(target);
  if (internal) {
    navigate(target);
  } else {
    window.location = target;
  }
};

const Button = props => {
  const {
    route,
    title,
  } = props
  return (
    <button
      className={cn(styles.button, 'py-2 px-5 mt-2 text-sm tracking-wide font-semibold')}
      onClick={() => doNavigate(route)}
    >
      {title}
    </button>
  );
  };

export default Button;

import React from "react";
import PortableText from "./portableText";

import { cn } from "../lib/helpers";
import styles from './blog-post-preview-grid.module.css'

const TextEditor = props => {

  return (
    <section className={cn(styles.greyBackground), "py-12"}>
      <div className="container max-w-5xl mx-auto m-8">
      <h1 className="text-white text-center text-4xl uppercase font-semibold tracking-wide">{props.title}</h1>
        <div className="w-full my-4">
          <PortableText blocks={props.body} />
        </div>
      </div>
    </section>
  );
};

export default TextEditor;

import React from "react";

import { cn } from "../lib/helpers";

import Button from './button'
import EventPreview from "./event-preview";
import styles from './blog-post-preview-grid.module.css'

const reduceEvents = events => {
  const now = new Date()
  const reducer = (acc, current) => {
    const {
      node: {
        eventDate,
        ...props
      } = {}
    } = current
    const date = new Date(eventDate)

    if (now < date) {
      return {
        future: [
          ...acc.future,
          {
            eventDate,
            ...props
          },
        ],
        past: acc.past,
      }
    } else {
      return {
        future: acc.future,
        past: [
          ...acc.past,
          {
            eventDate,
            ...props
          },
        ],
      }
    }
  }
  return events.reduce(reducer, {past: [], future: []})
}

function EventsPreviewGrid({ homePage, nodes = {}, title }) {
  const {
    edges = []
  } = nodes
  const reducedEvents = reduceEvents(edges)

  if (homePage === true) {

    return (
      <section className={cn(styles.grayBackground, "py-20")}>
        <h1 className= "text-white text-center text-4xl uppercase font-semibold tracking-wide">{title}</h1>
        <div className="container w-8xl mx-auto my-8">
          <div className="flex flex-col items-center flex-wrap max-w-3xl mx-auto overflow-hidden">
                {reducedEvents.future.slice(0,3).map((node = {}) => {
                  return (
                  <div className="my-2 px-2 w-full overflow-hidden" key={node.id}>
                    <EventPreview homePage={true} {...node} />
                  </div>
                )})}
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            route={'/program'}
            title={'ZOBRAZIT VŠECHNY AKCE'}
          />
        </div>
      </section>
      )
  }

  return (
    <>
  <section className={cn("py-20 text-white relative", styles.grayBackground)}>
    <h1 className="text-white text-center text-4xl uppercase font-semibold tracking-wide">{title}</h1>
    <div className="container max-w-5xl mx-auto m-8">
      <h2 className="text-xl uppercase tracking-wide">{'Nadcházející akce:'}</h2>
      <div className="flex flex-wrap -mx-2 overflow-hidden md:-mx-2 lg:-mx-2 xl:-mx-2 mt-4">
            {reducedEvents.future.map((node = {}) => {
              return (
              <div className="my-4 px-2 w-full overflow-hidden md:my-6 md:px-2 md:w-1/2 lg:my-6 lg:px-2 lg:w-1/3 xl:my-6 xl:px-2 xl:w-1/3" key={node.id}>
                <EventPreview {...node} />
              </div>
            )})}
      </div>
      </div>
  </section>
  <section className={cn("py-20 pt-40 text-white relative", styles.grayBackground)}>
  <svg
    className='absolute top-0 right-0'
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1149.91 163.56"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Layer_2-2" data-name="Layer 2">
          <g id="Layer_1-2-2" data-name="Layer 1-2">
            <path
              id="Path_8"
              data-name="Path 8"
              d="M1149.91,162.06H1002.85V105.18H957V53.77H921.17V1.5H0"
              style={{
                fill: 'none',
                stroke:'#e4bd00',
                strokeWidth:'2px',
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
    <div className="container max-w-5xl mx-auto m-8">
      <h2 className="text-xl uppercase tracking-wide">{'Proběhlé akce:'}</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden md:-mx-2 lg:-mx-2 xl:-mx-2 mt-4">
              {reducedEvents.past.map((node = {}) => {
                return (
                <div className="my-2 px-2 w-full overflow-hidden md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3" key={node.id}>
                  <EventPreview {...node} />
                </div>
              )})}
        </div>
      </div>
  </section>
  </>
  );
}

EventsPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default EventsPreviewGrid;

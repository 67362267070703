import React from "react";

export default ({ src, alt, caption }) => {
  if (!src) {
    return null;
  }

  return (
    <figure>
      <img src={src} alt={alt} />
      {/* <figcaption>{caption}</figcaption> */}
    </figure>
  );
};
